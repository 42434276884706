@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

html,
body,
#root,
.App {
  height: 100%;
}


/* Custom  */

/* Tailwind  */

@layer base {
  body {
    font-family: 'DM Sans', sans-serif; 
    /* font-weight: 500;  */
     /* src: url('/public/fonts/Nunito-Regular.ttf').format('truetype'); */
  }
  /* h1 {
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 1.5rem;
  } */
  /* @font-face {
    font-family: 'Nunito';
    src: url('/src/fonts/Nunito-Regular.ttf').format('truetype');
  } */
}

@keyframes ldio-vs3j6hxaga {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-vs3j6hxaga div {
  left: 48px;
  top: 24px;
  position: absolute;
  animation: ldio-vs3j6hxaga linear 0.8s infinite;
  background: #93dbe9;
  width: 4px;
  height: 12px;
  border-radius: 0px / 0px;
  transform-origin: 2px 26px;
}
.ldio-vs3j6hxaga div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.7578947368421053s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(2) {
  transform: rotate(18.94736842105263deg);
  animation-delay: -0.7157894736842105s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(3) {
  transform: rotate(37.89473684210526deg);
  animation-delay: -0.6736842105263158s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(4) {
  transform: rotate(56.8421052631579deg);
  animation-delay: -0.631578947368421s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(5) {
  transform: rotate(75.78947368421052deg);
  animation-delay: -0.5894736842105263s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(6) {
  transform: rotate(94.73684210526316deg);
  animation-delay: -0.5473684210526316s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(7) {
  transform: rotate(113.6842105263158deg);
  animation-delay: -0.5052631578947369s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(8) {
  transform: rotate(132.6315789473684deg);
  animation-delay: -0.4631578947368421s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(9) {
  transform: rotate(151.57894736842104deg);
  animation-delay: -0.42105263157894735s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(10) {
  transform: rotate(170.52631578947367deg);
  animation-delay: -0.37894736842105264s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(11) {
  transform: rotate(189.47368421052633deg);
  animation-delay: -0.3368421052631579s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(12) {
  transform: rotate(208.42105263157896deg);
  animation-delay: -0.29473684210526313s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(13) {
  transform: rotate(227.3684210526316deg);
  animation-delay: -0.25263157894736843s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(14) {
  transform: rotate(246.31578947368422deg);
  animation-delay: -0.21052631578947367s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(15) {
  transform: rotate(265.2631578947368deg);
  animation-delay: -0.16842105263157894s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(16) {
  transform: rotate(284.2105263157895deg);
  animation-delay: -0.12631578947368421s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(17) {
  transform: rotate(303.1578947368421deg);
  animation-delay: -0.08421052631578947s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(18) {
  transform: rotate(322.10526315789474deg);
  animation-delay: -0.042105263157894736s;
  background: #93dbe9;
}
.ldio-vs3j6hxaga div:nth-child(19) {
  transform: rotate(341.05263157894734deg);
  animation-delay: 0s;
  background: #93dbe9;
}
.loadingio-spinner-spinner-tpcw71p1job {
  width: 61px;
  height: 61px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-vs3j6hxaga {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.61);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-vs3j6hxaga div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

.background-red{
    background-color: #ff0000;
}